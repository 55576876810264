<template>
    <!-- 成功 -->
    <div class="succeed-wrap">
        <img src="~assets/images/index/successful-exchange.png" class="static-img"/>
        <div class="succeed-hint">兑换成功</div>

        <!--  线下课兑换码  -->
        <div class="code-box align-center" v-if="code != ''" >
          <div class="vertical-center">
            <span>兑换码：</span>
            <span class="code-text">{{code}}</span>
            <span class="copy-btn copy-btn" :data-clipboard-text="code" @click="copy">复制</span>
          </div>
        </div>

      <div class="code-box align-center link" v-if="link != ''" >
        <div class="link-item">
          <span class="link-item-t">提取链接：</span>
          <span class="code-text"><a :href="link">{{link.slice(0,30)}}</a>
            <span class="copy-btn copy-btn" :data-clipboard-text="link" @click="copy">复制</span>
          </span>

        </div>
        <div class="link-item" v-if="linkCode !='' && linkCode != null">
          <span class="link-item-t">提取码：</span>
          <span class="code-text">{{linkCode}}</span>
        </div>
      </div>

        <div class="succeed-btn theme-bg" @click="order">查看订单</div>

    </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  name: "orderSucceed",
  data(){
    return{
      code: '',
      link: '',
      linkCode:''
    }
  },
  created() {
    if(this.$route.query.code){
      this.code = this.$route.query.code
    }
    if(this.$route.query.link){
      this.link = this.$route.query.link
    }
    if(this.$route.query.linkCode){
      this.linkCode = this.$route.query.linkCode
    }
  },
  methods: {
    order(){
      this.$router.replace({
        path: '/user/record'
      })
    },
    // 点击复制事件
    copy(){
      var clipboard = new Clipboard('.copy-btn');
      clipboard.on('success', e => {
        this.$toast('复制成功');
        // 释放内存
        clipboard.destroy()
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$toast('该浏览器不支持自动复制');
        // 释放内存
        clipboard.destroy()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/order/succeed.scss";
</style>